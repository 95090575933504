import React, { useCallback, useEffect, useState } from "react";
import {
  ProForm,
  ProFormText,
  PageContainer,
  ProFormMoney,
  ProFormDigit,
  ProFormSelect,
  ProFormSwitch,
} from "@ant-design/pro-components";
import { useParams } from "react-router";
import { notification } from "antd";
import {
  getTicketTypeClubs,
  getTicketTypeData,
  patchTicketType,
} from "../../../../api";
import { CustomizeOptionsByName } from "../../../../utils/customizeOptions";
import { TICKET_COLORS } from "../../../../utils/constant";
import { GatesAPI } from "../../../../api/gates";
import ImageUpload from "../../../../components/UploadImage";

const EditTicketType = ({ ticketId, fetchTicketTypesByEventId, cancel }) => {
  const { id } = useParams();
  const [form] = ProForm.useForm();

  const [ticketTypeData, setTicketTypeData] = useState();
  const [clubs, setClubs] = useState([]);
  const [gates, setGates] = useState([]);
  const [images, setImages] = useState({ ticketTypeImage: null });

  const handleSetImage = (value, key, callBack) => {
    form.setFieldValue(key, value);

    callBack((prevState) => ({ ...prevState, [key]: value.url }));
    form.validateFields([key]);
  };

  const handleRemoveImage = (key) => {
    form.setFieldValue(key, null);
  };

  const fetchTicketTypeClubs = useCallback(async () => {
    try {
      const response = await getTicketTypeClubs(id);
      const responseGates = await GatesAPI.getGatesByEventId(id);

      setClubs(CustomizeOptionsByName(response.clubs));
      setGates(CustomizeOptionsByName(responseGates?.data ?? []));
    } catch (error) {
      console.log("Error", error);
    }
  }, [id]);

  const handleSubmit = useCallback(
    async (values) => {
      const { clubId, ticketColor } = values;
      const data = {
        clubId: clubId ?? null,
        ...values,
        ticketTypeImage: images.ticketTypeImage || null,
        ticketColor: {
          ...(ticketColor?.backgroundColor
            ? { ...ticketColor }
            : TICKET_COLORS.find(
                (color) => color.value === ticketColor?.value
              )),
        },
      };
      console.log("datadatadata", data);
      try {
        const response = await patchTicketType(
          ticketTypeData?.id || ticketId,
          data
        );
        if (response.status === 200) {
          cancel();
          fetchTicketTypesByEventId();
          notification.success({
            message: "Edit ticket type successfully! My friends",
          });
        }
      } catch (error) {
        console.log("Error", error);
        notification.error({
          message: "Something went wrong!",
        });
      }
    },
    [
      cancel,
      ticketId,
      fetchTicketTypesByEventId,
      images.ticketTypeImage,
      ticketTypeData,
    ]
  );

  const fetchTicketTypeData = useCallback(async () => {
    try {
      const response = await getTicketTypeData(ticketId);
      setTicketTypeData({
        ...response,
      });
    } catch (error) {
      console.log("Error", error);
    }
  }, [ticketId]);

  useEffect(() => {
    fetchTicketTypeData();
  }, [fetchTicketTypeData]);

  useEffect(() => {
    fetchTicketTypeClubs();
  }, [fetchTicketTypeClubs]);

  useEffect(() => {
    if (ticketTypeData?.ticketTypeImage) {
      setImages(ticketTypeData.ticketTypeImage);
    }
  }, [ticketTypeData]);

  return (
    <PageContainer title="Edit Ticket Type">
      {ticketTypeData && (
        <ProForm
          name="editTicketType"
          layout="horizontal"
          initialValues={{
            ...ticketTypeData,
            ...(ticketTypeData?.ticketColor
              ? {
                  ticketColor: {
                    value: `${ticketTypeData?.ticketColor?.textColor}/${ticketTypeData?.ticketColor?.backgroundColor}`,
                  },
                }
              : {}),
          }}
          onFinish={handleSubmit}
          form={form}
        >
          <ProForm.Group>
            <div className="form-fields-wrapper">
              <ProFormText
                name="nameEn"
                label="Name in English"
                width={"md"}
                rules={[{ required: true }]}
              />
              <ProFormText
                name="nameAr"
                label="Name in Arabic"
                width={"md"}
                rules={[{ required: true }]}
              />
              {/* <ProFormText
                name="ticketIdOdoo"
                label="Ticket Odoo Id"
                width={"md"}
                rules={[{ required: false }]}
              /> */}
              {clubs.length > 0 && (
                <ProFormSelect
                  label="Club"
                  name="clubId"
                  width={"md"}
                  placeholder="Select club"
                  options={clubs}
                  showSearch
                />
              )}
              <ProFormMoney
                name="price"
                label="Price"
                width={"md"}
                localeCustomSymbol={"SA"}
                min={0}
                customSymbol={"SAR"}
                rules={[{ required: true }]}
              />
              <ProFormDigit
                name="limitForUser"
                label="Limit buying tickets"
                width={"md"}
                min={1}
                rules={[{ required: true }]}
              />
              <ProFormDigit
                name="remainingQuantity"
                label="Amount of tickets available"
                width={"md"}
                min={0}
                rules={[{ required: true }]}
              />

              {gates.length > 0 && (
                <ProFormSelect
                  label="Gate"
                  name="gateId"
                  width={"md"}
                  placeholder="Select club"
                  options={gates}
                  showSearch
                />
              )}
              <ProFormSelect
                label="Color"
                name="ticketColor"
                width={"md"}
                rules={[
                  {
                    required: true,
                    message: () => (
                      <p className="error-message">
                        Color is required! My friends
                      </p>
                    ),
                  },
                ]}
                placeholder="Select ticket color"
                options={TICKET_COLORS}
                fieldProps={{
                  labelInValue: true,
                  optionItemRender: (item) => {
                    return (
                      <div
                        style={{
                          backgroundColor: item.backgroundColor,
                          color: item.textColor,
                          padding: "0px 8px",
                          borderRadius: 4,
                        }}
                      >
                        <p
                          style={{
                            margin: 0,
                          }}
                        >
                          {item.label}
                        </p>
                      </div>
                    );
                  },
                }}
                showSearch
              />
              <ProFormSwitch name="isBlock" label="Is block?" />
            </div>
          </ProForm.Group>
          <ProForm.Group>
            <ProForm.Item
              label="Ticket image (Optional):"
              name="ticketTypeImage"
            >
              <ImageUpload
                initialValue={ticketTypeData?.ticketTypeImage}
                callBackGetUrl={(value) =>
                  handleSetImage(value, "ticketTypeImage", setImages)
                }
                deleteable={true}
                removeImageFnc={handleRemoveImage("ticketTypeImage")}
              />
            </ProForm.Item>
          </ProForm.Group>
        </ProForm>
      )}
    </PageContainer>
  );
};

export default EditTicketType;
