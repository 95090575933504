import { useCallback, useEffect, useState } from "react";
import { PageContainer, ProDescriptions } from "@ant-design/pro-components";
import dayjs from "dayjs";
import { Button, Card, Divider, Image } from "antd";
import { getTicketTypeData } from "../../../api";

const TicketTypeDetails = ({
  ticketId,
  handleChangeDrawState,
  handleShowDeleteModal,
  handleShowBlockModal,
  ticketData,
}) => {
  const [ticketTypeData, setTicketTypeData] = useState();

  const fetchTicketTypeData = useCallback(async () => {
    try {
      const response = await getTicketTypeData(ticketId);

      setTicketTypeData(response);
    } catch (error) {
      console.log("Error", error);
    }
  }, [ticketId]);

  useEffect(() => {
    fetchTicketTypeData();
  }, [fetchTicketTypeData]);

  return (
    <>
      {ticketTypeData && (
        <PageContainer className="ticket-draw-container">
          <Card title="Ticket Type Information">
            <ProDescriptions column={1}>
              <ProDescriptions.Item
                label="Ticket ID"
                valueType="text"
                contentStyle={{
                  maxWidth: "80%",
                }}
              >
                {ticketTypeData?.id}
              </ProDescriptions.Item>

              <ProDescriptions.Item
                label="Ticket Odoo Id"
                valueType="text"
                contentStyle={{
                  maxWidth: "80%",
                }}
              >
                {ticketTypeData?.ticketIdOdoo}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="English name" valueType="text">
                {ticketTypeData?.nameEn}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="Arabic name" valueType="text">
                {ticketTypeData?.nameAr}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="Ticket color" valueType="color">
                {ticketTypeData?.ticketColor?.backgroundColor}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="Created at">
                {dayjs(ticketTypeData?.createdAt).format("YYYY-MM-DD HH:mm")}
              </ProDescriptions.Item>
            </ProDescriptions>
          </Card>

          <Divider />

          <Card title="Related Information">
            <ProDescriptions column={1}>
              <ProDescriptions.Item
                label="Price"
                valueType="text"
                contentStyle={{
                  maxWidth: "80%",
                }}
              >
                {ticketTypeData?.price}
              </ProDescriptions.Item>

              <ProDescriptions.Item
                label="Gate"
                contentStyle={{
                  maxWidth: "80%",
                }}
              >
                {ticketTypeData?.gate?.nameAr}
              </ProDescriptions.Item>

              <ProDescriptions.Item
                label="Limit buying tickets"
                contentStyle={{
                  maxWidth: "80%",
                }}
              >
                {ticketTypeData?.limitForUser}
              </ProDescriptions.Item>

              <ProDescriptions.Item
                label="Remaining tickets"
                contentStyle={{
                  maxWidth: "80%",
                }}
              >
                {ticketTypeData?.remainingQuantity}
              </ProDescriptions.Item>

              <ProDescriptions.Item
                label="English club name"
                valueType="text"
                contentStyle={{
                  maxWidth: "80%",
                }}
              >
                {ticketTypeData?.club?.nameEn}
              </ProDescriptions.Item>

              <ProDescriptions.Item
                label="Arabic club name"
                valueType="text"
                contentStyle={{
                  maxWidth: "80%",
                }}
              >
                {ticketTypeData?.club?.nameAr}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="Logo">
                <Image width={150} src={ticketTypeData?.club?.logo} />
              </ProDescriptions.Item>
              <ProDescriptions.Item label="Ticket Image">
                <Image width={150} src={ticketTypeData?.ticketTypeImage} />
              </ProDescriptions.Item>
            </ProDescriptions>
          </Card>

          <Divider />

          <div className="ticket-types-actions-wrapper">
            <Button
              key="submit"
              type="primary"
              onClick={() => handleChangeDrawState()}
            >
              Edit ticket type
            </Button>

            <div className="ticket-types-actions_second_section">
              <Button
                className="block-button"
                type="primary"
                ghost
                onClick={handleShowBlockModal}
              >
                {ticketData.isBlock ? "Unblock" : "Block"}
              </Button>

              <Button
                className="delete-button"
                type="primary"
                danger
                onClick={handleShowDeleteModal}
              >
                Delete
              </Button>
            </div>
          </div>
        </PageContainer>
      )}
    </>
  );
};

export default TicketTypeDetails;
